import React, {createContext, useState, useEffect, useContext} from 'react';
import {axiosInstance} from "../Api/AxiosConfig";
import {getCookie, getFromLocalStorage, gotoLoginPage, saveToLocalStorage, UnprotectedPage} from "../Hooks/UseGenericFunctions";
import {getUserHash} from "../Hooks/UseBaseData";

const GlobalDataContext = createContext();

export const useGlobalDataContext = () => {
    return useContext(GlobalDataContext);
}

export const GlobalDataProvider = ({children}) => {
    const [globalData, setGlobalData] = useState(null);
    const [basketData, setBasketData] = useState(null);
    const [loggedIn, setLoggedIn] = useState(false);
    const [showroomMode, setShowroomMode] = useState(false);
    const APP_VERSION = "02.00.21"; // Remember to also change the version number in public/version.txt so they match

    const readTextFile = async (url) => {
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const text = await response.text(); // Read the response body as text
            return text;
        } catch (error) {
            console.error('Error reading the file:', error);
        }
    };

    const compareToAppServerVersion = () => {
        const lastRefreshTime = Number(getFromLocalStorage('LAST_REFRESH_TIME'));
        const waitMiliseconds = 10 * 1000;
        const now = new Date().getTime();

        if (lastRefreshTime && (now < (lastRefreshTime + waitMiliseconds))) {
            console.log(`Less than ${waitMiliseconds / 1000} seconds since last refresh, skipping.`);
            return;
        }

        const versionUrl = `/version.txt?nocache=${now}`; // Add timestamp to prevent caching

        readTextFile(versionUrl).then((version) => {
            console.log("App version: " + APP_VERSION);
            if (APP_VERSION !== version) {
                window.location.href = window.location.href.split('?')[0] + '?nocache=' + new Date().getTime();
                console.log("Made a full reload of the page");
                saveToLocalStorage('LAST_REFRESH_TIME', now);
            }
        });
    }

    const registerUserWithGoogleAnalytics = () => {
        window.dataLayer.push({'event': 'userData', 'userId': getUserHash()});
    }

    const fetchGlobalData = () => {
        axiosInstance
            .get("shop/get-global-data")
            .then((response) => {
                setGlobalData(response.data);
                setBasketData(response.data.basket);
                setLoggedIn(true);
                compareToAppServerVersion();
                registerUserWithGoogleAnalytics();
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 401 || error.response.status === 500) {
                        setLoggedIn(false);
                    }
                }
            });
    };

    const checkLoginStatus = () => {
        axiosInstance
            .post("check", {"token": getCookie('CARZELLE_API_TOKEN')})
            .then((response) => {
                if (response.data === 'Success') {
                    fetchGlobalData();
                    setLoggedIn(true);
                } else {
                    setLoggedIn(false);
                    if (!UnprotectedPage()) {
                        gotoLoginPage();
                    }
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 401 || error.response.status === 500) {
                        setLoggedIn(false);
                    }
                }
            });
    }

    useEffect(() => {
        checkLoginStatus();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <GlobalDataContext.Provider value={{globalData, basketData, loggedIn, showroomMode, setShowroomMode, fetchGlobalData}}>
            {children}
        </GlobalDataContext.Provider>
    );
};
