export const filterAndSortList = (data, filterData, sortByField, sortByFieldAscending) => {
    let dataToFilter = data;

    // Brand (make_ids) filter
    if (filterData.make_ids.length > 0) {
        dataToFilter = dataToFilter.filter((car) => filterData.make_ids.includes(car.make_id));
    }

    // Fuel type filter
    if (filterData.fuel_ids.length > 0) {
        dataToFilter = dataToFilter.filter((car) => filterData.fuel_ids.includes(car.fuel_id));
    }

    // Car type filter
    if (filterData.car_type_ids.length > 0) {
        dataToFilter = dataToFilter.filter((car) => filterData.car_type_ids.includes(car.car_type_id));
    }

    // Price filter
    if (filterData.max_price > 0 || filterData.min_price > 0) {
        const minPrice = filterData.min_price > 0 ? filterData.min_price * 1000 : 0;
        const maxPrice = filterData.max_price > 0 ? filterData.max_price * 1000 : 999999999;
        dataToFilter = dataToFilter.filter((car) => car.price >= minPrice && car.price <= maxPrice);
    }

    // KM filter
    if (filterData.max_km > 0 || filterData.min_km > 0) {
        const minKm = filterData.min_km > 0 ? filterData.min_km * 1000 : 0;
        const maxKm = filterData.max_km > 0 ? filterData.max_km * 1000 : 999999999;
        dataToFilter = dataToFilter.filter((car) => car.km >= minKm && car.km <= maxKm);
    }

    // Damage amount filter
    if (filterData.max_damage_amount > 0 || filterData.min_damage_amount > 0) {
        const minDamageAmount = filterData.min_damage_amount > 0 ? filterData.min_damage_amount * 100 : 0;
        const maxDamageAmount = filterData.max_damage_amount > 0 ? filterData.max_damage_amount * 100 : 999999999;
        dataToFilter = dataToFilter.filter((car) => car.damage_amount >= minDamageAmount && car.damage_amount <= maxDamageAmount);
    }

    // Search text filter
    if (filterData.search_text !== '') {
        dataToFilter = dataToFilter.filter((car) =>
            (car.designation + car.vin_no + car.car_id + car.info_line_1 + car.info_line_2)
                .toLowerCase()
                .includes(filterData.search_text.toLowerCase())
        );
    }

    // Liked filter
    if (filterData.liked === true) {
        dataToFilter = dataToFilter.filter((car) => car.like !== null);
    }

    // In basket filter
    if (filterData.in_basket === true) {
        dataToFilter = dataToFilter.filter((car) => car.offer_amount > 0 || car.in_basket);
    }

    // Open offers filter
    if (filterData.open_offers === true) {
        dataToFilter = dataToFilter.filter((car) => car.offer_amount_confirmed > 0);
    }

    // Fast delivery filter
    if (filterData.fast_delivery === true) {
        dataToFilter = dataToFilter.filter((car) => car.buy_status === 'fast_delivery');
    }

    // Sorting logic
    dataToFilter = dataToFilter.sort((a, b) => {
        const aValue = a[sortByField];
        const bValue = b[sortByField];

        if (aValue == null && bValue == null) return 0;
        if (aValue == null) return sortByFieldAscending ? -1 : 1;
        if (bValue == null) return sortByFieldAscending ? 1 : -1;

        const aValueNumber = Number(aValue);
        const bValueNumber = Number(bValue);

        if (!isNaN(aValueNumber) && !isNaN(bValueNumber)) {
            return sortByFieldAscending
                ? aValueNumber - bValueNumber
                : bValueNumber - aValueNumber;
        }

        const aDate = new Date(aValue);
        const bDate = new Date(bValue);

        if (!isNaN(aDate.getTime()) && !isNaN(bDate.getTime())) {
            return sortByFieldAscending ? aDate - bDate : bDate - aDate;
        }

        const aValueStr = String(aValue);
        const bValueStr = String(bValue);

        if (aValueStr < bValueStr) return sortByFieldAscending ? -1 : 1;
        if (aValueStr > bValueStr) return sortByFieldAscending ? 1 : -1;
        return 0;
    });

    return dataToFilter;
};