import React from "react";
import {CarzelleTooltip} from "../System/Messages/CarzelleTooltip";

export const EquipmentIcons = ({carId, equipment, equipmentBaseIcons, className, opacity, size}) => {
    const getIconDetailsFromId = (id) => {
        const equipment = equipmentBaseIcons.find(equipment => equipment.id === id);
        return {
            icon_url: equipment?.icon_url,
            title: equipment?.name,
        };
    };

    return (
        <div>
            {equipment !== null && equipment.length > 0 &&
                <div className="flex items-center">
                    {equipment.split(',').map(Number).map((iconId) => {
                        const result = getIconDetailsFromId(iconId);
                        return (
                            <div key={"car_icon_" + carId + "_" + iconId} className="mr-1">
                                <CarzelleTooltip message={result.title}>
                                    <img className={`${className}`} src={result.icon_url} title={result.title} alt={result.title} style={{width: size, height: size, opacity: opacity}}/>
                                </CarzelleTooltip>
                            </div>
                        )
                    })}
                </div>
            }
        </div>
    )
}