import {useState} from "react";
import {ChevronDownIcon} from "@heroicons/react/24/outline/index.js";

export const MakeMultiSelect = ({makes, selectedBrandIds, onFilterChange, className}) => {
    const [showList, setShowList] = useState(false);

    const toggleSelection = (brandId) => {
        let newSelection;
        if (selectedBrandIds.includes(brandId)) {
            newSelection = selectedBrandIds.filter(id => id !== brandId);
        } else {
            newSelection = [...selectedBrandIds, brandId];
        }
        onFilterChange(newSelection);
    };

    const getSelectedTexts = () => {
        let selectedTexts = [];
        makes.forEach(make => {
            if (selectedBrandIds.includes(make.make_id)) {
                selectedTexts.push(make.make_name);
            }
        });
        return selectedTexts;
    }

    return (
        <div className={`relative ${className}`}>
            <div className="flex items-center w-full justify-between px-3 py-2 rounded-lg border border-gray-300" onClick={() => setShowList(!showList)}>
                <div className="text-gray-400 overflow-hidden text-xs">
                    {getSelectedTexts().length === 0 ? "Choose Makes" : ""}
                    {getSelectedTexts().map( (text, index) => (
                        <span key={index} className="mr-1 text-black">{text}{index !== getSelectedTexts().length-1 ? ',' : ''}</span>
                    ))}
                </div>
                <div><ChevronDownIcon className="w-6 h-6 text-gray-500" /></div>
            </div>
            <div className={`absolute top-[45px] shadow-xl left-0 z-50 w-full bg-white p-4 rounded-xl border transition-all duration-300 ease-out ${showList ? 'opacity-0 scale-95' : 'opacity-100 scale-100'}`}>
                {makes.map((make) => (
                        <div key={make.make_id} className="mr-3 mb-1">
                            <div id={'make-' + make.make_id} onClick={() => toggleSelection(make.make_id)} className={`relative flex items-center justify-start px-2 cursor-pointer hover:scale-[1.1] transition duration-150 ease-in-out py-1 bg-white border-b-gray-100`}>
                                <div className="absolute top-0 right-0 rounded opacity-25 bg-gray-400 w-full h-full" style={{display: selectedBrandIds.includes(make.make_id) ? 'block' : 'none'}}/>
                                <div className="mr-1" style={{display: make.make_logo === null ? 'none' : 'block'}}>
                                    <img src={make.make_logo} alt={make.make_name} className="h-4"/>
                                </div>
                                <div className="text-xs uppercase px-2">
                                    {make.make_name}
                                </div>
                            </div>
                        </div>
                    )
                )}
            </div>
        </div>
    )
        ;
}