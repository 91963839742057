import React, {useEffect, useState} from "react";
import {axiosInstance} from "../../Api/AxiosConfig";
import {ShowroomCarCard} from "./ShowroomCarCard/ShowroomCarCard";
import {Waiting} from "../../Components/System/Waiting";
import {ShowroomCarView} from "./ShowroomCarView";
import {useDisclosure} from "@mantine/hooks";
import {Drawer} from "@mantine/core";
import {CarFilter, InitControlValueData} from "../Presales/CarFilter";
import {findMinMaxDamageAmount, findMinMaxKm, findMinMaxPrices} from "../../Hooks/UseGenericFunctions";
import {filterAndSortList} from "../../Hooks/UseCarFilter";
import {ShowRoomTopMenu} from "./ShowRoomTopMenu";

export const Showroom = ({selected}) => {
    const [updateData, setUpdateData] = useState(false);
    const [makes, setMakes] = useState();
    const [carTypes, setCarTypes] = useState();
    const [cars, setCars] = useState();
    const [fuels, setFuels] = useState();
    const [selectedCar, setSelectedCar] = useState(null);
    const [opened, {open, close}] = useDisclosure(false);
    const [equipmentBaseIcons, setEquipmentBaseIcons] = useState();
    const [filterData, setFilterData] = useState(InitControlValueData);
    const [sortByField, setSortByField] = useState('designation');
    const [sortByFieldAscending, setSortByFieldAscending] = useState(true);

    useEffect(() => {
        if (selected) {
            axiosInstance
                .get('/shop/buy-list?view-id=2')

                .then((response) => {
                    setMakes(response.data.makes);
                    setCars(response.data.list);
                    setFuels(response.data.fuel);
                    setCarTypes(response.data.carTypes);
                    setEquipmentBaseIcons(response.data.equipmentBase);
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        }
    }, [updateData]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleCardClicked = (car) => {
        setSelectedCar(car);
        open();
    }

    if (cars) {
        const {minPrice, maxPrice} = findMinMaxPrices(cars);
        const {minKm, maxKm} = findMinMaxKm(cars);
        const {minDamageAmount, maxDamageAmount} = findMinMaxDamageAmount(cars);

        return (
            <>
                <div className="w-full px-8 pt-6">
                    <ShowRoomTopMenu makes={makes} fnFilterChange={(filterValues) => setFilterData(filterValues)} />
                </div>
                <div className="max-h-screen style={{height: '92vh'}} w-full p-8 flex justify-between">
                    <div className="flex flex-wrap overflow-y-scroll">
                        {filterAndSortList(cars, filterData, sortByField, sortByFieldAscending).map((car, index) => (
                            <div key={"card_" + car.hash} className="mr-8 mb-8">
                                <ShowroomCarCard car={car} key={index} selectedCar={selectedCar} fnCardClicked={handleCardClicked} equipmentBaseIcons={equipmentBaseIcons}/>
                            </div>
                        ))}
                    </div>
                    <div className="min-w-[350px] h-full">
                        <div className="sticky top-0">
                            <CarFilter makes={makes} fuels={fuels} carTypes={carTypes} filterData={filterData} fnFilterChange={(filterValues) => setFilterData(filterValues)} minPrice={minPrice} maxPrice={maxPrice} minKm={minKm} maxKm={maxKm} minDamageAmount={minDamageAmount} maxDamageAmount={maxDamageAmount}/>
                        </div>
                    </div>
                </div>

                <Drawer
                    position="right"
                    size="1100px"
                    padding="md"
                    opened={opened}
                    onClose={close}
                    withOverlay={true}
                    withCloseButton={false}
                    lockScroll={false}
                >
                    <ShowroomCarView car={selectedCar} close={close} equipmentBaseIcons={equipmentBaseIcons}/>
                </Drawer>
            </>
        )
    } else {
        return (
            <Waiting/>
        )
    }

}