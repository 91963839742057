import React, { useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { NotFound404 } from "./Pages/Messages/NotFound404";
import { Login } from "./Pages/Security/Login";
import './App.css';
import './input.css';
import { OrderReceived } from "./Pages/Messages/OrderReceived";
import { DailyOverview } from "./Pages/Management/Dashboards/DailyOverview";
import { MantineProvider } from "@mantine/core";
import { getPusherCluster } from "./Hooks/UseBaseData";
import { ChangePassword } from "./Pages/Security/ChangePassword";
import { ForgottenPassword } from "./Pages/Security/ForgottenPassword";
import { GlobalDataProvider } from "./GlobalState/GlobalDataProvider";
import { Logout } from "./Pages/Security/Logout";
import { Dashboard } from "./Pages/Dashboard/Dashboard";
import {PerformanceMonitor} from "./Pages/System/PerformanceMonitor";
import {CarList} from "./Pages/Mobile/CarList/CarList";
import {CheckoutMobile} from "./Pages/Mobile/Checkout/CheckoutMobile";
import {MobileTerms} from "./Pages/Mobile/Terms/MobileTerms";
import {DashboardPagesEnum} from "./Enums/DashboardPagesEnum";
import {GuidanceBox} from "./Pages/Experiments/GuidanceBox";

function App() {
    const [defaultPath, setDefaultPath] = useState(null);

    useEffect(() => {
        // Detect if the device is mobile and set the default path accordingly
        const path = window.matchMedia("(max-width: 768px)").matches ? "/mobile" : "/dashboard/" + DashboardPagesEnum.carlist;
        setDefaultPath(path);
    }, []);

    let goToLogin = (getPusherCluster() === null && window.location.pathname !== '/login' && window.location.pathname !== '/forgottenpassword' && window.location.pathname !== '/changepassword');

    useEffect(() => {
        if (goToLogin) {
            window.location = '/login';
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (defaultPath === null) {
        return <div>Loading...</div>;
    }

    return (
        <GlobalDataProvider>
            <MantineProvider theme={{ colorScheme: 'light' }}>
                <div className="App h-screen w-screen">
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" element={<Navigate to={defaultPath} replace />} />
                            <Route path="/dashboard/:menuItem?/:filterView?" element={<Dashboard />} />
                            <Route path="/orderreceived" element={<OrderReceived />} />
                            <Route path="/experiment" element={<GuidanceBox title="How to purchase a car" contents="To purchase a car you need to add it the the basket and then click Purchase. After that you will get an invoice" className="max-w-[420px]" />} />
                            <Route path="/logout" element={<Logout />} />
                            <Route path="/managementdashboard" element={<DailyOverview />} />
                            <Route path="/login" element={<Login />} />
                            {/*<Route path="/login" element={<UnderMaintenance/>}/>*/}
                            {/*<Route path="/" exact element={<UnderMaintenance />}/>*/}
                            <Route path="/changepassword" element={<ChangePassword />} />
                            <Route path="/forgottenpassword" element={<ForgottenPassword />} />
                            <Route path="/performancemonitor" element={<PerformanceMonitor />} />
                            <Route path="/mobile" element={<CarList />} />
                            <Route path="/mobile/terms" element={<MobileTerms />} />
                            <Route path="/mobile/checkout" element={<CheckoutMobile />} />
                            {/*<Route path="*" element={<Navigate to="/login" replace />} />*/}
                            <Route path="*" element={<NotFound404 />} />
                        </Routes>
                    </BrowserRouter>
                </div>
            </MantineProvider>
        </GlobalDataProvider>
    );
}

export default App;
