import {CarAttribute} from "../../Components/Cars/CarAttribute";
import {CarImageModal} from "../../Components/Cars/CarImageModal";
import {CarNavigationThumbs} from "../../Components/Cars/CarNavigationThumbs";
import React, {useEffect, useState} from "react";
import {axiosInstance} from "../../Api/AxiosConfig";
import {Waiting} from "../../Components/System/Waiting";
import InformationSection from "../../Components/Information/InformationSection";
import AddressMap from "../../Components/Maps/AddressMap";
import {OrderTransportSelection} from "./OrderTransportSelection";

export const OrderDetailView = ({selectedCar, showDocumentSection, showTrackingSection, fnUpdateData}) => {
    const [data, setData] = useState(null);
    const [showImageModal, setShowImageModal] = useState(false);
    const [showImageIndex, setShowImageIndex] = useState(0);
    const [deliveryAddress, setDeliveryAddress] = useState(null);
    const [deliveryChoice, setDeliveryChoice] = useState(null);
    const [refreshData, setRefreshData] = useState(false);

    const handleThumbnailClick = (index) => {
        setShowImageIndex(index);
        setShowImageModal(true);
    }

    const handleMainImageClick = () => {
        if (data.images.all.length > 0) {
            setShowImageIndex(0);
            setShowImageModal(true);
        }
    }

    const resolveDeliveryAddress = (data) => {
        if (data.transport_select.currentSelect.hash !== "") {
            setDeliveryAddress(data.transport_select.currentSelect.address + ' ' + data.transport_select.currentSelect.zip_code + ' ' + data.transport_select.currentSelect.city);
        } else {
            setDeliveryAddress('');
        }
    }

    useEffect(() => {
        if (selectedCar) {
            axiosInstance
                .get(selectedCar.info_endpoint)

                .then((response) => {
                    setData(response.data);
                    setDeliveryChoice(response.data.transport_select.currentSelect.hash === undefined ? null : response.data.transport_select.currentSelect.hash);
                    resolveDeliveryAddress(response.data);
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCar, refreshData]);

    if (data) {
        return (
            <div className="mt-12">
                <div className="w-full flex justify-center">
                    <img className='rounded-full h-20 w-20 object-scale-down cursor-pointer' onClick={handleMainImageClick} src={selectedCar.list_image} alt={'image for ' + selectedCar.designation}/>
                </div>
                <div>
                    <div className='text-center text-2xl font-bold mt-4'>{selectedCar.designation}</div>
                </div>
                <div className={`relative mt-4 `}>
                    <AddressMap address={deliveryAddress} carId={data.cars.id.value} />
                </div>
                <div className='flex mt-4 ml-6'>
                    <div className="w-full">
                        {data.transport_select.options !== undefined && !data.transport_select.locked &&
                            <InformationSection title="Transport Information" collapsable={true} expand={showTrackingSection || data.transport_select.currentSelect.hash === ""} informationMissing={!data.transport_select.locked && data.transport_select.currentSelect.hash === ""}>
                                <div className={`my-4`}>
                                    <div className="font-bold text-sm mb-1">Please deliver the car to ...</div>
                                    <OrderTransportSelection locked={data.transport_select.locked} deliveryChoice={deliveryChoice} setDeliveryChoice={setDeliveryChoice} transportOptions={data.transport_select.options} saveEndPoint={data.transport_select.endpoint} fnUpdateData={() => {setRefreshData(!refreshData);fnUpdateData()}}/>
                                </div>
                            </InformationSection>
                        }
                        {data.transport_select.currentSelect.carsTransport && data.transport_select.locked &&
                            <InformationSection title="Transport Information" collapsable={true} expand={showTrackingSection && data.transport_select.currentSelect.hash !== ""} informationMissing={false}>
                                <div className="text-sm">
                                    {data.transport_select.currentSelect.carsTransport.map((transport, index) => (
                                        <div key={"tracking_link_" + index} className="flex items-center mb-1">
                                            <a href={transport.tt_url} target="_blank" rel="noopener noreferrer" className="underline">Click to see tracking information</a>
                                        </div>

                                    ))}
                                </div>
                            </InformationSection>
                        }

                        <InformationSection title="Document Tracking" collapsable={true} expand={showTrackingSection}>
                            {data.shipments.length > 0 &&
                                <div className="text-sm">
                                    {data.shipments.map((shipment, index) => (
                                        <div key={"shipment_" + index} className="flex items-center mb-1">
                                            <a href={shipment.tt_url} target="_blank" rel="noopener noreferrer" className="underline">{shipment.type}</a>
                                        </div>
                                    ))}
                                </div>
                            }
                            {data.shipments.length === 0 &&
                                <div className="text-sm">
                                    No tracking information available
                                </div>
                            }
                        </InformationSection>

                        <InformationSection title="Car information" collapsable={true} expand={!showDocumentSection && !showTrackingSection}>
                            <div className='w-full'>
                                <div className='text-sm'>
                                    <CarAttribute label='Car #' value={selectedCar.id}/>
                                    <CarAttribute label='VIN' value={selectedCar.vin_no}/>
                                    <CarAttribute label='Make' value={data.cars.make_name.value}/>
                                    <CarAttribute label='Model' value={data.cars.model.value}/>
                                    <CarAttribute label='Model year' value={data.cars.model_year.value}/>
                                    <CarAttribute label='First reg.' value={data.cars.reg_date.value}/>
                                </div>
                                <div className='text-sm mt-4'>
                                    <CarAttribute label='KM' value={data.cars.km.value}/>
                                    <CarAttribute label='Color' value={data.cars.color.value === null ? data.cars.factory_color.value : data.cars.color.value}/>
                                    <CarAttribute label='Car type' value={data.cars.car_type_name.value}/>
                                    <CarAttribute label='Body type' value={data.cars.body_type_name.value}/>
                                    <CarAttribute label='Seats' value={data.cars.seats.value}/>
                                </div>
                                <div className='text-sm mt-4'>
                                    <CarAttribute label='Fuel' value={data.cars.fuel_name.value}/>
                                    <CarAttribute label='CO2 rating' value={data.cars.co2_rating_wltp.value}/>
                                    <CarAttribute label='Gear' value={data.cars.gear_name.value}/>
                                    {data.cars.battery_size.value !== null && <CarAttribute label='Battery size' value={data.cars.battery_size.value}/>}
                                    {data.cars.fuel_name.value !== 'Electric' && <CarAttribute label='Engine size' value={data.cars.engine_size.value}/>}
                                    <CarAttribute label='HP' value={data.cars.horsepower.value}/>
                                </div>
                            </div>
                        </InformationSection>

                        {data.cars.condition.value !== null &&
                            <InformationSection title="Condition" collapsable={true} expand={true}>
                                <div className='text-sm whitespace-normal'>
                                    {data.cars.condition.value}
                                </div>
                            </InformationSection>
                        }

                        {data.equipment.length > 0 &&
                            <InformationSection title="Equipment" collapsable={true} expand={!showDocumentSection && !showTrackingSection}>
                                <div className='max-w-[750px] whitespace-normal'>
                                    {data.equipment.map((item, index) => (
                                        <span key={item.name + index} className='mb-[10px] whitespace-nowrap mr-2 px-2 py-1 rounded bg-gray-200 inline-block text-sm' style={{lineHeight: '15px'}}>{item.name}</span>
                                    ))}
                                </div>
                            </InformationSection>
                        }

                        <InformationSection title="Images" collapsable={true} expand={!showDocumentSection && !showTrackingSection}>
                            <div className='flex flex-wrap items-center my-4'>
                                <CarImageModal images={data.images.all} visible={showImageModal} setVisible={setShowImageModal} imageIndex={showImageIndex}/>
                                <CarNavigationThumbs images={data.images.all} maxImagesNumber={20} onClick={handleThumbnailClick}/>
                            </div>
                        </InformationSection>

                        {data.documents.length > 0 &&
                            <InformationSection title='Order Documents' collapsable={true} expand={showDocumentSection}>
                                <div className='flex-1 text-sm'>
                                    {data.documents.map((item, index) => (
                                        <div key={item.file_url + index} className="flex mb-1">
                                            <div><img src="/images/pdf_new.png" alt="pdf document" className="w-6 mr-3"/></div>
                                            <div><a href={item.file_url} target="_blank" className="underline" rel="noopener noreferrer">{item.cars_documents_types_name}</a></div>
                                        </div>
                                    ))}
                                </div>
                            </InformationSection>
                        }
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <Waiting/>
        )
    }
}