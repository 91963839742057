export const DashboardPagesEnum = Object.freeze({
    mycarzelle: 'mycarzelle',
    showroom: 'showroom',
    room: 'room',
    terms: 'terms',
    orderview: 'orders',
    carlist: 'carlist',
});


