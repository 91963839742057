import React, {useEffect, useRef, useState} from "react";
import {axiosInstance} from "../../Api/AxiosConfig";
import {Waiting} from "../../Components/System/Waiting";
import {ArrowLeftIcon, ChevronUpIcon} from "@heroicons/react/24/solid";
import {PurchaseTypePill} from "../../Components/Information/PurchaseTypePill";
import {formatNumberWithSeparator} from "../../Hooks/UseGenericFunctions";
import {VATIcon} from "../../Components/Cars/VATIcon";
import {CheckCircleIcon, ShoppingCartIcon} from "@heroicons/react/24/outline";
import {EquipmentIcons} from "../../Components/Equipment/EquipmentIcons";
import {ChevronDownIcon} from "@heroicons/react/24/outline/index.js";

const SectionHeader = ({title}) => (
    <div className="text-2xl font-bold mt-8 mb-4 border-b pb-8">
        {title}
    </div>
);

const CarAttribute = ({label, value}) => (
    <div className="flex items-start justify-between mb-2">
        <div className="mr-[100px] font-semibold whitespace-nowrap">{label}</div>
        <div className="text-gray-500 text-right">{value}</div>
    </div>
);

export const ShowroomCarView = ({car, equipmentBaseIcons, close}) => {
    const [carDetails, setCarDetails] = useState();
    const [selectedImage, setSelectedImage] = useState(0);
    const [isBottom, setIsBottom] = useState(false);
    const scrollContainerRef = useRef(null);

    useEffect(() => {
        axiosInstance
            .get(car.info_endpoint)

            .then((response) => {
                setCarDetails(response.data);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }, [car]);

    const handleScroll = () => {
        if (scrollContainerRef.current) {
            const {scrollTop, scrollHeight, clientHeight} = scrollContainerRef.current;
            setIsBottom(scrollTop + clientHeight >= scrollHeight - 5); // 5px threshold to handle rounding
        }
    };

    useEffect(() => {
        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer) {
            scrollContainer.addEventListener("scroll", handleScroll);
        }
        return () => {
            if (scrollContainer) {
                scrollContainer.removeEventListener("scroll", handleScroll);
            }
        };
    }, [scrollContainerRef.current]);

    if (carDetails) {
        return (
            <div className="relative">
                <div className="sticky top-0 left-0 p-8 bg-white z-50">
                    <div className="flex items-center text-gray-400 mb-4 cursor-pointer" onClick={close}>
                        <div className="mr-2"><ArrowLeftIcon className="w-5 h-5 "/></div>
                        <div>back</div>
                    </div>
                    <div className="flex items-center mb-5  ">
                        <div className="text-3xl font-semibold mr-4">
                            {car.designation}
                        </div>
                        <div>
                            <PurchaseTypePill car={car} className="rounded-2xl px-2"/>
                        </div>
                    </div>
                </div>
                <div ref={scrollContainerRef} className="h-screen overflow-y-scroll px-8">
                    <div className="flex items-start justify-between">
                        <div className="max-w-[1020px]">
                            {carDetails.images.all.length > 0 &&
                                <img className='object-cover rounded-xl w-[750px] max-h-[500px] h-[500px] ' src={carDetails.images.all[selectedImage].file_url} alt={'image for ' + car.designation}/>
                            }
                        </div>
                        <div className="w-[230px] max-h-[500px] overflow-y-auto">
                            {carDetails.images.all.filter(image => !image.damage).map((image, index) => (
                                <img key={index} className='cursor-pointer object-cover rounded-xl w-full mb-4' src={image.file_url} alt={'image for ' + car.designation} onClick={() => setSelectedImage(index)}/>
                            ))}
                        </div>
                    </div>

                    <div className="flex items-start justify-between">
                        <div className="w-[620px] mr-8">
                            <div>
                                <SectionHeader title="Details"/>
                            </div>
                            <div className="mb-8">
                                <EquipmentIcons carId={car.id} equipment={car.equipment_base_icons} equipmentBaseIcons={equipmentBaseIcons} size="24px" opacity={0.3}/>
                            </div>
                            <div className="flex items-start justify-between">
                                <div className="mr-6 w-1/2">
                                    <CarAttribute label="Car #" value={car.car_id}/>
                                    <CarAttribute label="VIN" value={car.vin_no}/>
                                    <CarAttribute label="Make" value={carDetails.cars.make_name.value}/>
                                    <CarAttribute label="Model" value={carDetails.cars.model.value}/>
                                    <CarAttribute label="Model year" value={carDetails.cars.model_year.value}/>
                                    <CarAttribute label="First reg." value={carDetails.cars.reg_date.value}/>
                                    <CarAttribute label="KM" value={car.km}/>
                                    <CarAttribute label="Color" value={car.color}/>
                                </div>
                                <div className="w-1/2">
                                    <CarAttribute label="Car type" value={carDetails.cars.car_type_name.value}/>
                                    <CarAttribute label="Body type" value={carDetails.cars.body_type_name.value}/>
                                    <CarAttribute label="Seats" value={carDetails.cars.seats.value}/>
                                    <CarAttribute label="Fuel" value={carDetails.cars.fuel_name.value}/>
                                    <CarAttribute label="CO2 rating" value={carDetails.cars.co2_rating_wltp.value}/>
                                    <CarAttribute label="Gear" value={carDetails.cars.gear_name.value}/>
                                    <CarAttribute label="HP" value={carDetails.cars.horsepower.value}/>
                                    {carDetails.cars.battery_size.value !== null && <CarAttribute label='Battery size' value={carDetails.cars.battery_size.value}/>}
                                    {carDetails.cars.fuel_name.value !== 'Electric' && <CarAttribute label='Engine size' value={carDetails.cars.engine_size.value}/>}
                                </div>
                            </div>
                            <div>
                                <SectionHeader title="Equipment"/>
                            </div>
                            <div className="">
                                {carDetails.equipment.map((equipment, index) => (
                                    <div key={"equipment_" + index} className="flex items-center mb-2">
                                        <div><CheckCircleIcon className="w-8 h-8 text-gray-600 mr-4" strokeWidth={0.8}/></div>
                                        <div className="whitespace-nowrap">{equipment.name}</div>
                                    </div>
                                ))
                                }
                            </div>
                        </div>
                        <div className="w-[600px] mt-8">
                            <div className="bg-white shadow-2xl rounded-2xl p-8">
                                <div className="text-gray-500">Pris</div>
                                <div className="text-3xl font-semibold flex items-center">{formatNumberWithSeparator(parseInt(car.price), '.')}&nbsp;{car.currency}&nbsp;<VATIcon vatStatusId={car.vat_status_id}/></div>
                                <div className="border-t mt-4 mb-4"></div>
                                <div>
                                    <button className="bg-green-400 rounded-xl text-white w-full p-3 flex justify-center">
                                        <div className="flex items-center">
                                            <div><ShoppingCartIcon className="w-6 h-6 mr-4"/></div>
                                            <div>Add to cart</div>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sticky bottom-0 left-0 w-full flex items-center justify-center bg-white py-2 opacity-75 z-50">
                    {!isBottom && <ChevronDownIcon className="w-8 h-8 "/>}
                    {isBottom && <ChevronUpIcon className="w-8 h-8 "/>}
                </div>
            </div>
        )
    } else {
        return (
            <Waiting/>
        )
    }
}