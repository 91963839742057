import {Drawer} from "@mantine/core";
import React from "react";
import {OverlayClose} from "./OverlayClose";


export const LeftRightPanel = ({children, position="left", opened, close}) => {
    return (
        <Drawer
            position={position}
            size="md"
            padding="md"
            opened={opened}
            onClose={close}
            withOverlay={false}
            withCloseButton={false}
            lockScroll={false}
        >
            <OverlayClose close={close} className="text-black top-4 right-4" />
            {children}
        </Drawer>
    )
}
