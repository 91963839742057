import {Switch} from "@mantine/core";
import React, {useState} from "react";
import {MakeMultiSelect} from "./Filtering/MakeMultiSelect";
import {SearchIcon} from "../../Components/Icons/SearchIcon";

const ToogleButton = ({title, active, onChange, className}) => {
    return (
        <div className={`${className}`}>
            <div className="font-semibold text-xs text-center mb-[8px]">{title}</div>
            <div className="flex justify-center">
                <Switch checked={active} classNames={{track: `cursor-pointer ${active ? 'bg-primary-color border-primary-color' : ''}`}} onChange={onChange} className='whitespace-nowrap'/>
            </div>
        </div>
    )
}

const CarSearchBox = ({value, setValue, className, placeholder="Search"}) => {
    return (
        <>
            <div className={`relative ${className}`}>
                <SearchIcon className="absolute inset-y-0 left-0 w-[45px]" />

                <input id="search" name="search"
                       className="block w-full border rounded-lg border-gray-300 pl-12 py-2 text-sm text-gray-900 placeholder-gray-400"
                       placeholder={placeholder} value={value} onChange={(event) => {setValue(event.currentTarget.value);setValue(event.currentTarget.value)}}
                />
            </div>
        </>
    )
}

export const ShowRoomTopMenu = ({makes, fnFilterChange}) => {
    const [fastDelivery, setFastDelivery] = React.useState(false);
    const [liked, setLiked] = React.useState(false);
    const [showRoomMode, setShowRoomMode] = React.useState(false);
    const [selectedBrandIds, setSelectedBrandIds] = useState([]);
    const [searchText, setSearchText] = useState('');

    const changeBrandPillSelection = (value) => {
        setSelectedBrandIds(value);
        fnFilterChange(prevState => ({
            ...prevState, make_ids: value
        }));
    }

    const changeFastDelivery = (value) => {
        fnFilterChange(prevState => ({
            ...prevState, fast_delivery: value
        }));
    }

    const changeLikeSelection = (value) => {
        fnFilterChange(prevState => ({
            ...prevState, liked: value
        }));
    }

    const changeSearchText = (value) => {
        setSearchText(value);
        fnFilterChange(prevState => ({
            ...prevState, search_text: value
        }));
    }

    return (
        <>
            <div className="p-5 bg-white rounded-2xl flex items-center justify">
                <div className="border-r">
                    <ToogleButton title="Liked" active={liked} onChange={(e) => {setLiked(e.currentTarget.checked);changeLikeSelection(e.currentTarget.checked);}} className="w-[100px] mr-4"/>
                </div>
                <div className="border-r">
                    <ToogleButton title="Fast delivery" active={fastDelivery} onChange={(e) => {setFastDelivery(e.currentTarget.checked);changeFastDelivery(e.currentTarget.checked);}} className="w-[100px] mr-4"/>
                </div>
                <div className="border-r">
                    <ToogleButton title="Showroom" active={showRoomMode} setActive={setShowRoomMode} className="w-[100px] mr-4"/>
                </div>
                <div className="border-r ml-4">
                    <MakeMultiSelect makes={makes} onFilterChange={changeBrandPillSelection} selectedBrandIds={selectedBrandIds} className="mr-4 w-[300px]" />
                </div>
                <div className="border-r ml-4">
                    <CarSearchBox value={searchText} setValue={changeSearchText} className="mr-4 w-[300px]"/>
                </div>
            </div>
        </>
    )
}