export default function InformationHeader({title, Icon, className="text-gray-600"}) {
    return (
        <div className={`flex items-center ${className}`}>
            {Icon &&
                <div>
                    <Icon className="w-6 h-6"/>
                </div>
            }
            <div className="font-bold">
                {title}
            </div>
        </div>
    )
}