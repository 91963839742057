import React, {useEffect, useState} from "react";
import {MapContainer, TileLayer, Marker, Popup, useMap} from "react-leaflet";
import L from "leaflet"; // Import Leaflet to define custom icons
import "leaflet/dist/leaflet.css";

// Define a custom icon
const customIcon = new L.Icon({
    iconUrl: "https://cdn-icons-png.flaticon.com/128/684/684908.png", // Change this to your marker image
    iconSize: [40, 40], // Size of the icon [width, height]
    iconAnchor: [20, 40], // Position the icon correctly
    popupAnchor: [0, -40], // Adjust popup position
});

const AddressMap = ({address, carId}) => {
    const [location, setLocation] = useState({lat: 56.128174, lng: 9.552683}); // Default location
    const [mapKey, setMapKey] = useState(address); // Used to force re-rendering

    useEffect(() => {
        const geocodeAddress = async () => {
            try {
                const response = await fetch(
                    `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(address)}`
                );
                const data = await response.json();
                if (data.length > 0) {
                    const newLocation = {lat: parseFloat(data[0].lat), lng: parseFloat(data[0].lon)};
                    setLocation(newLocation);
                    setMapKey(`${newLocation.lat},${newLocation.lng}`); // Force re-render
                } else {
                    console.error("No results found for the address.");
                }
            } catch (error) {
                console.error("Error fetching geocode:", error);
            }
        };

        if (address) {
            geocodeAddress();
        }
    }, [address, carId]);

    return (
        <div style={{position: "relative", width: "100%", height: "200px"}}>
            {/* OpenStreetMap at the bottom */}
            <div className={`absolute flex items-center justify-center rounded-xl h-full w-full opacity-90 bg-gray-200 ${address !== '' ? 'hidden' : ''}`} style={{zIndex: 200}}>
                <div>
                    <div className="text-xl font-bold">No delivery address specified</div>
                    <div className="text-center">Select delivery address below</div>
                </div>
            </div>
            <MapContainer
                key={mapKey}
                center={location}
                zoom={15}
                className="rounded-xl"
                style={{height: "100%", width: "100%", position: "absolute", zIndex: 0}}
                attributionControl={false}
                zoomControl={false}
            >
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                <Marker position={location} icon={customIcon}>
                    <Popup>{address}</Popup>
                </Marker>
                <ChangeView center={location}/>
            </MapContainer>

            {/* Absolute Overlay Div */}
            <div
                className={`${address === '' ? 'hidden' : ''}`}
                style={{
                    position: "absolute",
                    bottom: "10px",
                    left: "10px",
                    zIndex: 1000, // Higher than the map
                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                    padding: "10px",
                    borderRadius: "5px",
                    boxShadow: "0px 2px 5px rgba(0,0,0,0.2)",
                    fontSize: "14px"
                }}>
                📍 Delivery to: <strong>{address}</strong>
            </div>
        </div>
    );
};

// Component to move the map when the location changes
const ChangeView = ({center}) => {
    const map = useMap();
    useEffect(() => {
        map.setView(center, 15);
    }, [center, map]);
    return null;
};

export default AddressMap;
