import {ChevronDownIcon} from "@heroicons/react/24/outline/index.js";
import {useState, useEffect} from "react";
import InformationHeader from "./InformationHeader";

export default function InformationSection({title, children, collapsable = false, expand = true, informationMissing, className, Icon, ...props}) {
    const [expanded, setExpanded] = useState(expand);

    useEffect(() => {
        setExpanded(expand);
    }, [expand])

    const SectionIcon = function(){
        if(Icon){
            return <Icon className="w-4 h-4 text-slate-600"/>
        }
        return null;
    }

    return (                    
        <div className={`${className} border-t py-2 border-gray-200 `} {...props}>
            
            <div className="flex items-center justify-between py-2 hover:bg-slate-50 transition-all duration-300">
                <InformationHeader Icon={SectionIcon} title={title} className={informationMissing ? 'text-red-600' : ''}/>
                {collapsable && <ChevronDownIcon onClick={() => setExpanded(!expanded)} className={`cursor-pointer hover:text-gray-700 text-slate-500 w-5 h-5 transition-all duration-300 ${expanded ? 'rotate-180' : ''}`}/>}
            </div>
            {expanded &&
                <div className="relative pt-3">
                    {children}
                </div>
            }   
        </div>
    )
}