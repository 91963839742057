import {DocumentIcon, KeyIcon} from "@heroicons/react/24/outline";
import {BookOpenIcon as BookOpenIconSolid, DocumentIcon as DocumentIconSolid, KeyIcon as KeyIconSolid} from "@heroicons/react/24/solid";
import React from "react";
import {CarzelleTooltip} from "../../Components/System/Messages/CarzelleTooltip";

export const Elements = ({elements}) => {
    return (
        <div className={`flex items-center justify-between`}>
            {elements.keys &&
                <div>
                    <CarzelleTooltip message="Second key will arrive with documents">
                        <KeyIconSolid className={`w-6 h-6 mr-1 text-green-500`} strokeWidth={0.8} title="Second key will arrive with documents"/>
                    </CarzelleTooltip>
                </div>
            }
            {!elements.keys &&
                <div>
                    <CarzelleTooltip message="Keys status is unknown">
                        <KeyIcon className={`w-6 h-6 mr-1`} strokeWidth={1}/>
                    </CarzelleTooltip>
                </div>
            }
            {elements.documents &&
                <div>
                    <CarzelleTooltip message="Documents are available. Click on the red PDF icon to see the documents">
                        <DocumentIconSolid className={`w-6 h-6 mr-1 text-green-500`} strokeWidth={1}/>
                    </CarzelleTooltip>
                </div>
            }
            {!elements.documents &&
                <div>
                    <CarzelleTooltip message="Awaiting documents">
                        <DocumentIcon className={`w-6 h-6 mr-1`} strokeWidth={1}/>
                    </CarzelleTooltip>
                </div>
            }
            {elements.servicebook &&
                <div>
                    <CarzelleTooltip message="Service book is available and will arrive with documents">
                        <BookOpenIconSolid className={`w-6 h-6 mr-1 text-green-500`} strokeWidth={0.8}/>
                    </CarzelleTooltip>
                </div>
            }
        </div>
    )
}