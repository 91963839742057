import React, {useEffect, useState} from "react";
import {axiosInstance} from "../../Api/AxiosConfig";
import {PresalesListTable} from "./PresalesListTable";
import {Waiting} from "../../Components/System/Waiting";
import {CarFilter, InitControlValueData} from "./CarFilter";
import {CarDetailsView} from "./CarDetailsView";
import {useGlobalDataContext} from "../../GlobalState/GlobalDataProvider";
import {registerUserActivity} from "../../Api/StatService";
import {StatusAndCheckoutButton} from "./StatusAndCheckoutButton";
import {useLocation} from "react-router-dom";
import {findMinMaxDamageAmount, findMinMaxKm, findMinMaxPrices} from "../../Hooks/UseGenericFunctions";
import {filterAndSortList} from "../../Hooks/UseCarFilter";

export const Presales = ({fnUpdateGlobalData, pushUpdate}) => {
    const [data, setData] = useState();
    const [makes, setMakes] = useState();
    const [fuels, setFuels] = useState();
    const [carTypes, setCarTypes] = useState();
    const [equipmentIcons, setEquipmentIcons] = useState();
    const [sortByField, setSortByField] = useState('designation');
    const [sortByFieldAscending, setSortByFieldAscending] = useState(true);
    const [filterData, setFilterData] = useState(InitControlValueData);
    const [updateCarList, setUpdateCarList] = useState(false);
    const [activeCar, setActiveCar] = useState();
    const [showDetails, setShowDetails] = useState(false);
    const {globalData} = useGlobalDataContext();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const showBasket = searchParams.get('showBasket');

    const isShowBasket = showBasket === 'true';

    useEffect(() => {
        axiosInstance
            .get('/shop/buy-list', {
                params: {
                    listTypeId: 0
                }
            })

            .then((response) => {
                fnUpdateGlobalData();
                setEquipmentIcons(response.data.equipmentBase);
                setMakes(response.data.makes);
                setData(response.data.list);
                setFuels(response.data.fuel);
                setCarTypes(response.data.carTypes);
                registerUserActivity(3, 'Fetched Presales list');
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }, [updateCarList, pushUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setShowDetails(true);
    }, [activeCar]);

    const animateBasketIcon = () => {
        const element = document.getElementById('shopping_bag');  // Get the div element

        element.classList.add('animate-bounce');
        element.classList.remove('bg-purchase-color')
        element.classList.add('bg-sky-500');

        setTimeout(() => {
            element.classList.remove('bg-sky-500');
            element.classList.remove('animate-bounce');
            element.classList.add('bg-purchase-color');
        }, 1650);
    }

    if (data && globalData) {

        const {minPrice, maxPrice} = findMinMaxPrices(data);
        const {minKm, maxKm} = findMinMaxKm(data);
        const {minDamageAmount, maxDamageAmount} = findMinMaxDamageAmount(data);

        return (
            <>
                {/*<LoadingOverlay visible={fetchingData} zIndex={20} overlayProps={{radius: "sm", blur: 2}}/>*/}
                <div className="lg:hidden flex items-center justify-center mt-12 p-8 text-2xl">
                    <div className="text-center">The presales function can only be used on desktop computers</div>
                </div>

                {/*<ScreenSizeIndicator/>*/}

                <div className="hidden lg:block">
                    <div className="mx-4 flex" style={{height: '92vh'}}>
                        <div className="relative mr-1 2xl:mr-4 h-[92vh] w-[100vw]">
                            <div className="absolute top-0 right-[-35px] z-20">
                                <StatusAndCheckoutButton cars={globalData.basket.basket_lines} sum={globalData.basket.basket_totals.amount} currency={globalData.basket.basket_totals.currency} showBasket={isShowBasket} fnUpdate={() => setUpdateCarList(!updateCarList)}/>
                            </div>
                            <div className={`overflow-auto ${(activeCar == null || activeCar === undefined || !showDetails) ? 'h-full' : 'h-[calc(92vh-265px)]'}`}>
                                <PresalesListTable cars={filterAndSortList(data, filterData, sortByField, sortByFieldAscending)} equipmentIcons={equipmentIcons} activeCarId={activeCar === null || activeCar === undefined ? '' : activeCar.hash} activeSortField={sortByField} activeSortAscending={sortByFieldAscending} fnSetSortByField={setSortByField} fnSetSortByFieldOrder={setSortByFieldAscending} fnUpdateCarList={() => {
                                    setUpdateCarList(!updateCarList);
                                    animateBasketIcon();
                                }} fnSetActiveCar={setActiveCar}/>
                            </div>
                            {(activeCar !== null && activeCar !== undefined && showDetails) &&
                                <div className="w-full bg-white mt-2 rounded-lg p-4 h-[255px] min-h-[255px] max-h-[255px]">
                                    <CarDetailsView car={activeCar} height="h-[220px]" closeDetails={() => setShowDetails(false)} equipmentIcons={equipmentIcons}/>
                                </div>
                            }
                        </div>
                        <div className="min-h-[94vh]">
                            <div className="pb-4 h-full min-w-[170px]">
                                <CarFilter makes={makes} fuels={fuels} carTypes={carTypes} filterData={filterData} fnFilterChange={(filterValues) => setFilterData(filterValues)} minPrice={minPrice} maxPrice={maxPrice} minKm={minKm} maxKm={maxKm} minDamageAmount={minDamageAmount} maxDamageAmount={maxDamageAmount}/>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    } else {
        return (
            <Waiting/>
        );
    }
}