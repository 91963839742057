import {BuildingStorefrontIcon, ScaleIcon, PowerIcon, MapPinIcon, HomeIcon} from "@heroicons/react/24/outline/index.js";
import React, {useState} from "react";
import {CarzelleLogo} from "../Brand/CarzelleLogo";
import {CarIcon} from "../Icons/CarIcon";
import {getPartnerInfoIcon, getPartnerInfoLogo, getUserCompanyName} from "../../Hooks/UseBaseData";
import {Link} from "react-router-dom";
import {DashboardPagesEnum} from "../../Enums/DashboardPagesEnum";

export const LeftNavigation = ({chosenMenuItemId, showroomMode}) => {
    const [collapsed, setCollapsed] = useState(true);
    const [, setIsHovered] = useState(false);

    const MenuItem = ({id, route, collapsed, choosenId, Icon, className, title, showDivider = false, newItem = false}) => {
        let chosenClassName = route === choosenId ? "bg-blue-100" : "";

        return (
            <div onClick={() => {
                setCollapsed(true);
            }} className={`${className} ${chosenClassName} rounded-2xl cursor-pointer transition-transform duration-600 whitespace-nowrap transform hover:shadow-sm hover:scale-[1.05] py-2 px-4 hover:bg-blue-50`}>
                <Link to={route} className="flex items-center">
                    <div className={`flex items-center ${collapsed ? 'justify-center' : ''}`}>
                        <div className={`${collapsed ? '' : 'mr-2'}`}><Icon strokeWidth={1.1} className={`w-7 h-7 text-gray-500`}/></div>
                        <div className={`${collapsed ? 'hidden' : 'animate-fadeIn'}`}>{title}</div>
                        {newItem && <div className={`${collapsed ? 'top-1 right-0' : 'hidden'} absolute opacity-55 bg-green-500 text-white text-[8px] rounded px-1`}>New</div>}
                    </div>
                </Link>
            </div>
        )
    }

    return (
        <div onMouseEnter={() => {
            setCollapsed(false);
            setIsHovered(true);
        }}
             onMouseLeave={() => {
                 setCollapsed(true);
                 setIsHovered(false);
             }}
             className={`${collapsed ? 'w-[80px] px-3' : 'w-[300px] px-8'} fixed transition-all rounded-r-3xl duration-300 z-40 top-0 min-h-screen flex flex-col bg-white shadow-xl py-6 text-gray-600`}>
            <div className={`absolute p-2 ${collapsed ? 'mt-4 font-black text-4xl' : 'font-bold mt-[24px] text-2xl'}`}>
                {!showroomMode &&
                    <div>
                        {collapsed && <CarzelleLogo width={40} withText={false}/>}
                        {!collapsed && <CarzelleLogo width={150}/>}
                    </div>
                }
                {(!(getPartnerInfoLogo() === 'null' || getPartnerInfoIcon() === 'null')) &&
                    <div className="mt-2">
                        {collapsed && <img src={getPartnerInfoIcon()} className="w-[40px]" alt='Partner icon'/>}
                        {!collapsed && <img src={getPartnerInfoLogo()} className="w-[150px]" alt='Partner icon'/>}
                    </div>
                }
                {!collapsed &&
                    <div className="text-gray-600 text-sm text-right mt-2">
                        {getUserCompanyName()}
                    </div>
                }
            </div>
            <div className="h-[200px]"/>
            <MenuItem id={10} route={"/dashboard/" + DashboardPagesEnum.mycarzelle} collapsed={collapsed} Icon={HomeIcon} choosenId={"/dashboard/" + chosenMenuItemId} title="My Carzelle"/>
            <MenuItem id={30} route={"/dashboard/" + DashboardPagesEnum.carlist} collapsed={collapsed} Icon={CarIcon} choosenId={"/dashboard/" + chosenMenuItemId} title="Car List"/>
            <MenuItem id={20} route={"/dashboard/" + DashboardPagesEnum.showroom} collapsed={collapsed} Icon={BuildingStorefrontIcon} choosenId={"/dashboard/" + chosenMenuItemId} title="Showroom"/>
            <MenuItem id={50} route={"/dashboard/" + DashboardPagesEnum.orderview} collapsed={collapsed} Icon={MapPinIcon} choosenId={"/dashboard/" + chosenMenuItemId} title="Orders"/>
            <MenuItem id={70} route={"/dashboard/" + DashboardPagesEnum.terms} collapsed={collapsed} Icon={ScaleIcon} choosenId={"/dashboard/" + chosenMenuItemId} title="Terms"/>
            <MenuItem id={80} route="/logout" collapsed={collapsed} Icon={PowerIcon} choosenId={chosenMenuItemId} title="Log out" className="absolute bottom-4"/>
        </div>
    )
}
