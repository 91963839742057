export const CarTypeSelection = ({carTypes, selectedCarTypeIds, onFilterChange}) => {
    const toggleSelection = (carTypeId) => {
        let newSelection;
        if (selectedCarTypeIds.includes(carTypeId)) {
            newSelection = selectedCarTypeIds.filter(id => id !== carTypeId);
        } else {
            newSelection = [...selectedCarTypeIds, carTypeId];
        }
        onFilterChange(newSelection);
    };

    return (
        <div className="flex flex-wrap">
            {carTypes.map((type) => (
                    <div key={type.id} className="mr-4 mb-1">
                        <div id={'make-' + type.id} onClick={() => toggleSelection(type.id)} className={`relative flex items-start justify-center cursor-pointer hover:scale-[1.1] transition duration-150 ease-in-out py-1 bg-white border-b border-b-gray-300`}>
                            <div className="absolute top-0 right-0 rounded opacity-25 bg-gray-400 w-full h-full" style={{display: selectedCarTypeIds.includes(type.id) ? 'block' : 'none'}}/>
                            <div className="text-xs uppercase px-2">
                                {type.name}
                            </div>
                        </div>
                    </div>
                )
            )}
        </div>
    )
        ;
}