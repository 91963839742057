import React, {useEffect, useState} from "react";
import {axiosInstance} from "../../Api/AxiosConfig";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faMinus} from "@fortawesome/free-solid-svg-icons";
import {DownloadFilesIconButton} from "../../Components/Buttons/DownloadFilesIconButton";
import {Elements} from "./Elements";
import {LogisticStatus} from "./LogisticStatus";


export const OrderLine = ({orderLine, rowNumber, selectedCarId, setSelectedCar, setSelectedCarId, setShowDocuments, setShowTracking}) => {
    const [expandedView, setExpandedView] = useState(false);
    const [, setData] = useState([]);
    // const [, setShowImageModal] = useState(false);
    // const [, setShowImageIndex] = useState(0);
    const [, setShowPrimaryImage] = useState(false);
    // const trackNTraceAvailable =
    //     (Array.isArray(data.transports) && data.transports.some(transport => transport.tt_url)) ||
    //     (Array.isArray(data.shipments) && data.shipments.some(shipment => shipment.tt_url));

    // const handleThumbnailClick = (index) => {
    //     setShowImageIndex(index);
    //     setShowImageModal(true);
    // }

    const handleExpandOrderClick = (showDocuments = false, showTracking = false) => {
        setShowPrimaryImage(false);
        setExpandedView(!expandedView);
        setSelectedCarId(orderLine.hash);
        setSelectedCar(orderLine);
        setShowDocuments(showDocuments);
        setShowTracking(showTracking);

    }

    useEffect(() => {
        if (orderLine.hash !== selectedCarId) {
            setExpandedView(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCarId]);


    useEffect(() => {
        if (expandedView) {
            axiosInstance
                .get(orderLine.info_endpoint)

                .then((response) => {
                    setData(response.data);
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expandedView]);

    const isEven = () => {
        return rowNumber % 2 === 0;
    }

    let rowColorClass = isEven() ? 'bg-white' : 'bg-gray-200';
    return (
        <div>
            <div className="relative flex flex-row items-center mb-2 text-gray-600 text-sm cursor-pointer">
                {/*<div className={`absolute h-full w-2 ${orderLine.hash === selectedCarId ? 'bg-gray-600 rounded-l' : ''}`}>*/}
                {/*</div>*/}
                <div className={`rounded py-1 flex items-center px-4 shadow text-gray-600 ${rowColorClass} ${orderLine.hash === selectedCarId ? 'border-gray-600 border' : ''} mr-4`} onClick={() => handleExpandOrderClick(false, false)}>
                    <div className="h-full w-[50px]">
                        <img className='rounded-full h-10 w-10 object-scale-down' src={orderLine.list_image} alt={'image for ' + orderLine.designation}/>
                    </div>
                    <div className="w-[80px] pl-2">
                        {orderLine.id}
                    </div>
                    <div className="w-[200px]">
                        {orderLine.vin_no}
                    </div>
                    <div className="w-[300px] overflow-hidden whitespace-nowrap mr-6">
                        {orderLine.designation}
                    </div>
                    <div className="w-[110px]" onClick={(e) => {e.stopPropagation();handleExpandOrderClick(false, true);}}>
                        <LogisticStatus status={orderLine.stock_status} />
                    </div>
                    <div className="w-[90px] flex justify-center">
                        <div>
                            {orderLine.paid_status === "1" &&
                                <FontAwesomeIcon icon={faCheck} size="1x" color="green" className="m-auto"/>
                            }
                            {orderLine.paid_status === "0" &&
                                <FontAwesomeIcon icon={faMinus} size="1x" color="red" className="m-auto"/>
                            }
                        </div>
                    </div>
                    <div className="w-[100px] justify-center flex items-center text-gray-600">
                        <Elements elements={orderLine.elements} />
                    </div>
                    <div className="w-[140px] text-center">
                        <button className={`shadow rounded py-1 bg-gray-500 font-bold px-6 text-white`} onClick={(e) => {e.stopPropagation();handleExpandOrderClick(false, true);}}>Track</button>
                    </div>
                    <div className="w-[100px] flex justify-center" onClick={(e) => {e.stopPropagation();handleExpandOrderClick(true, false);}}>
                        <img src="/images/pdf_new.png" alt="pdf document" className="w-6 mr-3"/>
                    </div>
                    <div className="w-[100px] text-center">
                        <div className={`${orderLine.image_download_url === '' ? 'hidden' : ''} flex justify-center`}>
                            <DownloadFilesIconButton url={orderLine.image_download_url}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}