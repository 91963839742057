import {LogisticStatusEnum} from "../../Enums/LogisticStatusEnum";

export const LogisticStatus = ({status}) => {
    const statusNumber = status.match(/^\d+/);
    return (
        <div>
            {statusNumber == 1 &&
                <div className="rounded-xl text-center bg-gray-300  italic text-xs px-2 py-1">
                    <div>{LogisticStatusEnum[statusNumber]}</div>
                    <div>{status.split("#")[1]}</div>
                </div>
            }
            {statusNumber == 2 &&
                <div className="rounded-xl text-center bg-gray-500 text-white text-xs px-2 py-1">
                    <div>{LogisticStatusEnum[statusNumber]}</div>
                    <div>{status.split("#")[1]}</div>
                </div>
            }
            {statusNumber == 3 &&
                <div className="rounded-xl text-center bg-green-400 text-white text-xs px-2 py-1">
                    <div>{LogisticStatusEnum[statusNumber]}</div>
                    <div>{status.split("#")[1]}</div>
                </div>
            }
            {statusNumber == 4 &&
                <div className="rounded-xl text-center bg-violet-400 text-gray-600 text-xs px-2 py-1">
                    <div>{LogisticStatusEnum[statusNumber]}</div>
                    <div>{status.split("#")[1]}</div>
                </div>
            }
        </div>
    )
}