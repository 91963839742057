import React from 'react';
import {SearchIcon} from "../../Components/Icons/SearchIcon";

export const CarSearchBox = ({value, setValue, placeholder="Search"}) => {
    return (
        <>
            <div className="relative w-full">
                <SearchIcon className="absolute inset-y-0 left-0 w-[45px]" />

                <input id="search" name="search"
                       className="block w-full border-b border-b-gray-300 pl-12 py-2 text-sm text-gray-900 placeholder-gray-400"
                       placeholder={placeholder} value={value} onChange={(event) => {setValue(event.currentTarget.value);setValue(event.currentTarget.value)}}
                />
            </div>
        </>
    )
}

