import {axiosInstance} from "../../Api/AxiosConfig";
import {faDownload} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";

export const DownloadFilesIconButton = ({Icon, url, ...props}) => {
    const downloadFile = (url) => {
        console.log(url);
        axiosInstance
            .get(url)

            .then((response) => {
                afterZipDownload(response.data);

            })
            .catch((error) => {
                console.error("Error fetching file:", error);
            });
    }

    function afterZipDownload(data) {
        var link = document.createElement("a");
        document.body.appendChild(link);
        link.setAttribute("type", "hidden");
        link.href = "data:text/plain;base64," + data.fileData;
        link.download = data.fileName;
        link.click();
        document.body.removeChild(link);
    }

    return (
        <>
            <div onClick={() => downloadFile(url)} className={`flex items-center w-fit cursor-pointer`} {...props}>
                <div><FontAwesomeIcon icon={faDownload} style={{fontSize: "1.4rem"}} color="gray" /></div>
            </div>
        </>
    )
}