import React from "react";
import {formatNumberWithSeparator} from "../../../Hooks/UseGenericFunctions";
import {VATIcon} from "../../../Components/Cars/VATIcon";
import {PurchaseTypePill} from "../../../Components/Information/PurchaseTypePill";
import {CarAttribute} from "./CarAttribute";
import {ChevronRightIcon} from "@heroicons/react/24/solid";
import {EquipmentIcons} from "../../../Components/Equipment/EquipmentIcons";

export const ShowroomCarCard = ({car, selectedCar, equipmentBaseIcons, fnCardClicked}) => {
    return (
        <div className="relative bg-white rounded-xl w-[430px] h-[530px] group overflow-hidden cursor-pointer" onClick={() => fnCardClicked(car)}>
            <div className="relative hover:opacity-80 bg-gray-300">
                <img className='object-cover rounded-t-xl w-[430px] h-[300px]' src={car.list_image_large} alt={'image for ' + car.designation}/>
            </div>
            <div className="p-6 absolute bottom-0 left-0 bg-white w-full transition-all duration-500 ease-in-out">
                {car.equipment_base_icons !== null &&
                    <div className="absolute left-2 top-[-40px] rounded-xl bg-white px-4 py-1">
                        <EquipmentIcons carId={car.id} equipment={car.equipment_base_icons} equipmentBaseIcons={equipmentBaseIcons} size="24px" opacity={0.3}/>
                    </div>
                }

                <div className="text-lg">
                    {car.designation}
                </div>
                <div className="font-bold text-2xl flex items-center">
                    {formatNumberWithSeparator(parseInt(car.price), '.')}&nbsp;{car.currency}&nbsp;<VATIcon vatStatusId={car.vat_status_id}/>
                </div>
                <div className="mt-1 mb-6 text-gray-100">
                    <PurchaseTypePill car={car} className="rounded-2xl px-2"/>
                </div>
                <div className="flex justify-between items-start">
                    <div className="border-r-1">
                        <CarAttribute label="First reg." value={car.reg_date_show}/>
                        <CarAttribute label="KM" value={car.km}/>
                        <CarAttribute label="Color" value={car.color}/>
                    </div>
                    <div>
                        <CarAttribute label="HP" value={car.horsepower}/>
                        <CarAttribute label="Fuel" value={car.fuel_name}/>
                        <CarAttribute label="Gear" value={car.gear_name}/>
                    </div>
                </div>
                <div className="max-h-0 opacity-0 group-hover:max-h-20 group-hover:opacity-100 transition-all duration-700 ease-in-out border-t mt-4">
                    <div className="flex items-center justify-between mt-4">
                        <div>Se mere</div>
                        <div><ChevronRightIcon className="w-6 h-6 text-gray-600" strokeWidth={1.6}/></div>
                    </div>
                </div>
            </div>
        </div>
    )
}