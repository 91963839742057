import {axiosInstance} from "../../Api/AxiosConfig";
import React, {useEffect, useState} from "react";
import {CarSearchBox} from "../../Components/Navigation/CarSearchBox";
import {InitControlValueData} from "../../Components/Navigation/CarFilterBar";
import {OrderLine} from "./OrderLine";
import {Waiting} from "../../Components/System/Waiting";
import {LeftRightPanel} from "../../Components/Navigation/LeftRightPanel";
import {useDisclosure} from "@mantine/hooks";
import {OrderDetailView} from "./OrderDetailView";

export const Orders = ({selected}) => {
    const [data, setData] = useState();
    const [filteredData, setFilteredData] = useState([]);
    const [filterBy,] = useState('All');
    const [updateData, setUpdateData] = useState(false);
    const [selectedCarId, setSelectedCarId] = useState('');
    const [selectedCar, setSelectedCar] = useState(null);
    const [filterData, setFilterData] = useState(InitControlValueData);
    // const [ordersMissingPayment, setOrdersMissingPayment] = useState([]);
    // const [ordersReadyForPickup, setOrdersReadyForPickup] = useState([]);
    // const [ordersMissingTransport, setOrdersMissingTransport] = useState([]);
    // const [, setOrdersArchived] = useState([]);
    // const [, setOrdersUnderway] = useState([]);
    const [opened, {open, close}] = useDisclosure(false);
    const [showDocumentsSection, setShowDocumentsSection] = useState(false);
    const [showTrackingSection, setShowTrackingSection] = useState(false);

    // let filterView = getUrlParameter('filterView', '0');

    useEffect(() => {
        if (selected) {
            axiosInstance
                .get('/shop/orders')

                .then((response) => {
                    setData(response.data.list);
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        }
    }, [updateData]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (selectedCar) {
            open();
        }
    }, [selectedCar]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (data) {
            if (filterBy === 'All') {
                setFilteredData(data);
            }
        }
    }, [filterBy, data]); // eslint-disable-line react-hooks/exhaustive-deps

    const filterList = (data) => {
        return data.filter(order =>
            order.vin_no.toLowerCase().includes(filterData.search_text.toLowerCase()) || order.designation.toLowerCase().includes(filterData.search_text.toLowerCase()) || order.id.toString().includes(filterData.search_text.toLowerCase())
        );
    }

    if (data && data.length > 0) {
        return (
            <>
                <div className={`hidden screen-small:block`}>

                    {/*<div className="flex items-center px-12">*/}
                    {/*    <div className="mr-8 text-sm">Filter list:</div>*/}

                    {/*    {ordersMissingPayment.length > 0 &&*/}
                    {/*        <OrdersFilterLink filterViewNumber='1' title="Missing payment" count={ordersMissingPayment.length} parentClass="mr-8" countClass="bg-red-200 text-black"/>*/}
                    {/*    }*/}

                    {/*    {ordersReadyForPickup.length > 0 &&*/}
                    {/*        <OrdersFilterLink filterViewNumber='2' title="Ready for pickup" count={ordersReadyForPickup.length} parentClass="mr-8" countClass="bg-primary-color text-white"/>*/}
                    {/*    }*/}

                    {/*    {ordersMissingTransport.length > 0 &&*/}
                    {/*        <OrdersFilterLink filterViewNumber='3' title="Missing transport selection" count={ordersMissingTransport.length} parentClass="mr-8" countClass="bg-red-200 text-black"/>*/}
                    {/*    }*/}

                    {/*    <OrdersFilterLink filterViewNumber='0' title="Show all" count={data.length} countClass="bg-primary-color text-white"/>*/}
                    {/*</div>*/}


                    <div className="min-w-[1800px] px-10">
                        <div className="sticky top-0 z-10 bg-gray-100 pt-8">
                            <div className="flex items-center mb-2 font-semibold">
                                <div className="rounded bg-white px-4 py-1 flex items-center shadow h-[90px] mr-4">
                                    <div className="w-[50px]">
                                    </div>
                                    <div className="w-[80px]">
                                        Car&nbsp;#
                                    </div>
                                    <div className="w-[200px]">
                                        VIN Number
                                    </div>
                                    <div className="w-[300px] overflow-hidden mr-6">
                                        Description
                                    </div>
                                    <div className="w-[110px] text-center">
                                        Logistics
                                    </div>
                                    <div className="w-[90px] text-center">
                                        Paid
                                    </div>
                                    <div className="w-[100px] text-center">
                                        Elements
                                    </div>
                                    <div className="w-[140px] text-right pr-4 whitespace-nowrap">
                                        Track & Trace
                                    </div>
                                    <div className="w-[100px] text-center pr-4">
                                        Documents
                                    </div>
                                    <div className="w-[100px] text-center">
                                        Photos
                                    </div>
                                </div>
                            </div>
                            <div className={`flex mr-3 items-center max-w-[1325px] mb-2 shadow`}>
                                <CarSearchBox fnSearchValueChange={(filterValues) => setFilterData(filterValues)} placeholder="Search - VIN Number, description"/>
                            </div>
                        </div>

                        <div className="ml-[1px]">
                            {filterList(filteredData).map((car, index) => {
                                return (
                                    <div key={'order_line_' + car.hash}>
                                        <OrderLine orderLine={car} rowNumber={index} setSelectedCar={setSelectedCar} setSelectedCarId={setSelectedCarId} selectedCarId={selectedCarId} setShowTracking={setShowTrackingSection} setShowDocuments={setShowDocumentsSection}/>
                                    </div>
                                )

                            })}
                        </div>

                    </div>

                    <div className="h-[500px]"></div>

                </div>

                <div className={`screen-small:hidden flex mt-[250px] justify-center w-screen h-screen`}>
                    <div className="font-bold text-gray-500 p-8 text-center">Not available on smartphone.<br/>Please use desktop computer or larger screen</div>
                </div>

                <LeftRightPanel position="right" opened={opened} close={close}>
                    <OrderDetailView selectedCar={selectedCar} showDocumentSection={showDocumentsSection} showTrackingSection={showTrackingSection} fnUpdateData={() => setUpdateData(!updateData)}/>
                </LeftRightPanel>
            </>
        )
    }

    if (!data) {
        return (
            <Waiting title="Loading orders ..."/>
        )
    }

    if (data && data.length === 0) {
        return (
            <div className={`w-full h-screen flex items-center justify-center font-medium text-3xl pb-16`}>
                No orders found
            </div>
        )
    }
}